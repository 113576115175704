import React from 'react'
import { Link } from 'gatsby'
import "../scss/NavigationBar.scss"
import { captureEvent } from 'event-service/modules/capture'

function NavigationBar(props) {
    const { siteNav } = props;

    let menu = siteNav.menu.map((item, i) => { // loops through menu items

        if (item.children && item.children.length > 0) { // if a drop down go down a deeper level and build drop down code
            let subMenu = item.children.map((subItem, x) => {
                if (subItem.children && subItem.children.length > 0) { // goes to the lowest level to check for drop downs within drop downs

                    let lowestMenuLevel = subItem.children.map((lowItem) => {
                        return lowItem.link.linkPath.length > 0 ? (
                            <Link id='nav_link' key={lowItem.link.linkName} className='nav-smallDrop' to={lowItem.link.linkPath}>
                                <span>{lowItem.link.linkName}</span>
                            </Link>
                        ) : (
                                <p key={lowItem.link.linkName} className='nav-smallDrop'>
                                    <span>{lowItem.link.linkName}</span>
                                </p>
                            )
                    })

                    return (
                        <li className="nav-li" key={x}>
                            <div className="button button_color-white button_font-small menu__button js-button">
                                <span className="button__subtitle js-button-subtitle dropHover">{subItem.link.linkName}</span>
                            </div>
                            <div className="nav-sub">
                                {lowestMenuLevel}
                            </div>
                        </li>
                    )
                } else {
                    return (
                        <li key={subItem.link.linkName}>
                            <Link id='nav_link' className="button button_color-white button_font-small menu__button js-button dropHover" to={subItem.link.linkPath}>
                                <span className="button__subtitle js-button-subtitle">{subItem.link.linkName}</span>
                            </Link>
                        </li>
                    )
                }
            })

            return item.link.linkPath.length > 0 ? (
                <div className="header__item js-header-item dropdown dropdown-6">
                    <Link id='nav_link' key={i} to={item.link.linkPath} className="">
                        <span className="button button_color-default button_font-small menu__button">
                            <span className="button__subtitle js-button-subtitle">{item.link.linkName} 
                                <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8">
                                    <g><g>
                                        <path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path>
                                    </g></g>
                                </svg>
                            </span>
                        </span>
                    </Link>
                    <ul id={item.link.linkName} className="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                        {subMenu}
                    </ul>
                </div>
            ) : (
                    <p key={i} className="header__item js-header-item dropdown dropdown-6">
                        <span className="button button_color-default button_font-small menu__button">
                            <span className="button__subtitle js-button-subtitle">{item.link.linkName} <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><g><g><path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path></g></g></svg></span>
                        </span>
                        <ul id={item.link.linkName} className="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                            {subMenu}
                        </ul>
                    </p>
                )
        } else {
            return item.link.linkPath.length > 0 ? (
                <Link id='nav_link' to={item.link.linkPath} key={item.link.linkName} className="header__item js-header-item " onClick={() => toggleMobile()}>
                    <span className="button button_color-default button_font-small menu__button js-button">
                        <span className="button__subtitle js-button-subtitle">{item.link.linkName}</span>
                    </span>
                </Link>
            ) : (
                <p key={item.link.linkName} className="header__item js-header-item " onClick={() => toggleMobile()}>
                    <span className="button button_color-default button_font-small menu__button js-button">
                        <span className="button__subtitle js-button-subtitle">{item.link.linkName}</span>
                    </span>
                </p>
            )
        }
    })

    let mobileMenu = siteNav.menu.map((item, i) => {
        if (item.children && item.children.length > 0) { // if a drop down go down a deeper level and build drop down code

            let subMenu = item.children.map((subItem, x) => {
                if (subItem.children && subItem.children.length > 0) {
                    let lowestMenuLevel = subItem.children.map((lowItem) => {
                        return subItem.link.linkPath.length > 0 ?
                            (
                                <Link id='nav_link' key={subItem.link.linkName} className="nav-link nav-drop" to={subItem.link.linkPath}>
                                    <span className="nav-span nav-small">{subItem.link.linkName}</span>
                                </Link>
                            ) : (
                                <p key={subItem.link.linkName} className="nav-link nav-drop">
                                    <span className="nav-span nav-small">{subItem.link.linkName}</span>
                                </p>
                            )
                    })

                    return (
                        <div key={x} className="nav-link nav-drop nav-secondDrop">
                            <span onClick={() => toggleDrop(subItem.link.linkName + '-mobile')} className="nav-span">{subItem.link.linkName} <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><g><g><path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path></g></g></svg></span>
                            <div className="nav-dropInfo" id={subItem.link.linkName + '-mobile'}>
                                {lowestMenuLevel}
                            </div>
                        </div>

                    )
                } else {
                    return subItem.link.linkPath.length > 0 ?
                        (
                            <Link id='nav_link' key={subItem.link.linkName} className="nav-link nav-drop" to={subItem.link.linkPath}>
                                <span className="nav-span nav-small">{subItem.link.linkName}</span>
                            </Link>
                        ) : (
                            <p key={subItem.link.linkName} className="nav-link nav-drop">
                                <span className="nav-span nav-small">{subItem.link.linkName}</span>
                            </p>
                        )
                }
            })

            return (
                <div key={i} className="nav-link">
                    <span onClick={() => toggleDrop(item.link.linkName + '-mobile')} className="nav-span">{item.link.linkName} <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><g><g><path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path></g></g></svg></span>
                    <div className="nav-dropInfo" id={item.link.linkName + '-mobile'}>
                        {subMenu}
                    </div>
                </div>
            )
        } else {
            return item.link.linkPath.length > 0 ? (
                <Link id='nav_link' key={item.link.linkName} className="nav-link" to={item.link.linkPath} onClick={() => toggleMobile()}>
                    <span className="nav-span">{item.link.linkName}</span>
                </Link>
            ) : (
                    <p key={item.link.linkName} className="nav-link" onClick={() => toggleMobile()}>
                        <span className="nav-span">{item.link.linkName}</span>
                    </p>
                )
        }
    })

    return (
        <header id="header">
            <div className="header site__section js-header">
                <section className="header__section site__subsection">
                    <Link id='nav_link' className="button button_type-icon header__logo icon_logo js-button nav-logo" to="/">
                        <img className="nav-logo nav-icon" src={props.dealerInfo.dealerLogo} alt={props.dealerInfo.dealerName} />
                    </Link>
                    <Link id='nav_link' className="lotus-font-secondary-title button button_type-icon header__logo js-button text_logo" to="/">
                        <span className='lotus-font-secondary-title'>{props.dealerInfo.dealerName.substring(0, props.dealerInfo.dealerName.indexOf(" "))}</span>
                        {props.dealerInfo.dealerName.substring(props.dealerInfo.dealerName.indexOf(" "), props.dealerInfo.dealerName.length)}
                    </Link>
                </section>
                <section className="header__section site__subsection">
                    <menu className="menu js-menu">
                        <div className="menu__wrapper">
                            {menu}
                        </div>
                    </menu>
                </section>
                <a onClick={() => toggleMobile()} className="burger header__explore-menu js-header-explore-button">
                    {/*<ul className="burger__buns">
                        <div className="menu-grid">
                            <li className="burger__bun"></li>
                            <li className="burger__bun"></li>
                            <li className="burger__bun"></li>
                        </div>
                        <div className="menu-grid">
                            <li className="burger__bun"></li>
                            <li className="burger__bun"></li>
                            <li className="burger__bun"></li>
                        </div>
                        <div className="menu-grid">
                            <li className="burger__bun"></li>
                            <li className="burger__bun"></li>
                            <li className="burger__bun"></li>
                        </div>
                    </ul>*/}
                    <span className="collapsible-menu" alt="hamburger icon for expandable menu"/>
                    <span className="menu-label">Menu</span>
                    
                </a>
                <section className="header__section site__subsection mobile_hidden js-menu">
                    <a href={'tel:' + props.dealerInfo.salesNumber} className="icon-spoiler submenu__explore-map js-icon-spoiler" data-target="js-mobile-map" data-id="map-spoiler" onClick={() => captureEvent({ 'event': 'asc_click_to_call', 'comm_phone_number': `${props.dealerInfo.salesNumber}`, 'department': 'sales'}, { handlers: ['ASC'] }).then(response => console.log(response)).catch(error => console.log(error))}>
                        <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="36" height="36" viewBox="0 0 36 36">
                            <path fill="#fff" className="st0" d="M35.6,30.2c-2,4.9-6.8,5.7-8.4,5.7c-0.5,0-7.6,0.4-17.8-9.3C1.2,18.8,0.2,10.4,0.1,8.8C0,7.2,0.4,3.3,5.8,0.4
                                        c0.7-0.4,2-0.5,2.3-0.2c0.2,0.2,4.7,7.6,4.8,7.8c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.2,0.8-0.7,1.3c-0.5,0.5-1,1-1.6,1.4
                                        c-0.6,0.4-1.1,0.9-1.6,1.3c-0.5,0.5-0.7,0.9-0.7,1.2c0,0.8,0.7,3.6,5.6,8c4.9,4.3,7.3,5.6,7.5,5.6c0.2,0.1,0.4,0.1,0.6,0.1
                                        c0.3,0,0.7-0.2,1.2-0.7c0.5-0.5,2.1-2.7,2.6-3.1c0.5-0.5,0.9-0.7,1.3-0.7c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,7.7,4.5,7.8,4.6
                                        C36.1,28.3,35.9,29.5,35.6,30.2"></path>
                        </svg>
                        <span className="linkSecondary" >{props.dealerInfo.salesNumber}</span>
                    </a>
                    <span className="icon-split"></span>
                    <Link id='nav_link' to={props.dealerInfo.directions} className="icon-spoiler directions submenu__explore-map js-icon-spoiler" data-target="js-mobile-map" data-id="map-spoiler">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="38" viewBox="0 0 30 38"><g><g><path fill="#fff" d="M15.038 22.155a7.192 7.192 0 0 1-7.192-7.193 7.192 7.192 0 0 1 7.192-7.192 7.192 7.192 0 0 1 7.193 7.192 7.192 7.192 0 0 1-7.193 7.193zM14.511.009C7.136.27 1.009 6.028.3 13.38a14.925 14.925 0 0 0 .297 4.723c.228 1.021.57 2.012.997 2.941 1.484 3.52 4.917 9.408 12.619 15.815a1.286 1.286 0 0 0 1.644 0C23.56 30.46 26.992 24.57 28.484 21.035c.434-.929.769-1.912.997-2.94a14.915 14.915 0 0 0 .365-3.276c0-8.365-6.918-15.107-15.335-14.81z"></path></g></g></svg>			        <div className="icon-spoiler__caption">
                            <div className="icon-spoiler__title">directions</div>
                        </div>
                    </Link>
                </section>
            </div>
            <section className='nav-mobile'>
                <div id="mobileDropDown" className="nav-cont">
                    <div className="nav-overflow">
                        {mobileMenu}
                    </div>
                </div>
            </section>
        </header>
    )
}

function toggleDrop(id) {
    const div = document.getElementById(id)
    div.classList.toggle('showDrop')
}

function toggleMobile() {
    document.getElementById("mobileDropDown").classList.toggle('showMobile');
    document.getElementsByClassName("header__explore-menu")[0].classList.toggle('state_toggled');
}

export default NavigationBar

